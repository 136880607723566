import React from "react";

import {
  Box,
  Grid,
  Container,
  useTheme,
  Typography,
  IconButton,
  Card,
  CardContent,
  Divider,
} from "@mui/material";

import { DeleteOutline } from "@mui/icons-material";
import moment from "moment";
import { MotionButton, MotionTypography } from "./admin/users/Users";
import { toast } from "react-toastify";

const dummyData = [
  {
    id: 1,
    userId: 1,
    title: "Video 1",
    thumbnail:
      "https://videosstoragebucket.s3.amazonaws.com/a04595ce-0028-40d0-b126-3aa07db69cf1/WhatsApp%20Image%202024-09-27%20at%207.28.41%20AM.jpeg?AWSAccessKeyId=AKIAR6DSZGYZPEMFSHH4&Expires=1731124866&Signature=iMJBk1rE0WF0PwMRN%2Bv4v4YF91g%3D",
    createdAt: new Date(),
  },
  {
    id: 2,
    userId: 2,
    title: "Video 2",
    thumbnail:
      "https://videosstoragebucket.s3.amazonaws.com/a04595ce-0028-40d0-b126-3aa07db69cf1/WhatsApp%20Image%202024-09-27%20at%207.28.41%20AM.jpeg?AWSAccessKeyId=AKIAR6DSZGYZPEMFSHH4&Expires=1731124866&Signature=iMJBk1rE0WF0PwMRN%2Bv4v4YF91g%3D",
    createdAt: new Date(),
  },
  {
    id: 3,
    userId: 3,
    title: "Video 3",
    thumbnail:
      "https://videosstoragebucket.s3.amazonaws.com/a04595ce-0028-40d0-b126-3aa07db69cf1/WhatsApp%20Image%202024-09-27%20at%207.28.41%20AM.jpeg?AWSAccessKeyId=AKIAR6DSZGYZPEMFSHH4&Expires=1731124866&Signature=iMJBk1rE0WF0PwMRN%2Bv4v4YF91g%3D",
    createdAt: new Date(),
  },
];

export const Downloads = () => {
  const theme = useTheme();

  const deleteDownload = (id) => {
   toast.success(`delete download with id ${id}`)
  };


  const DeleteAllDownloads = () => {
    toast.success("api to delete delete downloads")
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        py: "30px",

        flexWrap: "wrap",

        [theme.breakpoints.up("md")]: {
          marginLeft: theme.spacing(9) + 1,
        },
      }}
    >
      {dummyData.length === 0 ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"500px"}
        >
          <Typography variant="h3" fontWeight={700}>
            No Downloads to Show
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              py: 4,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              background: `linear-gradient(45deg, ${theme.palette.success.light} 30%, ${theme.palette.success.main} 90%)`,
              borderRadius: "15px",
              padding: "20px",
              marginBottom: "20px",
              boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
              gap: 2,
            }}
          >
            <MotionTypography
              variant="h3"
              fontWeight={700}
              initial={{ x: -50 }}
              animate={{ x: 0 }}
              transition={{ type: "spring", stiffness: 120 }}
              sx={{
                color: theme.palette.common.white,
                textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
              }}
            >
              Downloads
            </MotionTypography>

            <MotionButton
              variant="contained"
              initial={{ x: 50 }}
              animate={{ x: 0 }}
              transition={{ type: "spring", stiffness: 120 }}
              sx={{
                borderRadius: "50px",
                color: theme.palette.common.white,
                backgroundColor: theme.palette.success.dark,
                textTransform: "none",
                padding: "10px 20px",
                "&:hover": {
                  backgroundColor: theme.palette.success.main,
                  boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                },
              }}
              onClick={() => DeleteAllDownloads()}
            >
              Clear all
            </MotionButton>
          </Box>
          {dummyData.map((download) => (
            <Card
              variant="outlined"
              sx={{ mb: 2, backgroundColor: "#F9F9F9", cursor: "pointer", borderRadius: 3 }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ md: "center", xs: "start" }}
                  flexDirection={{ md: "row", xs: "column" }}
                  gap={1}
                >
                  <Box  sx={{ width: 70, height: 50, marginRight: 10 }}>
                  <img
                    src={download.thumbnail}
                    alt="Video Thumbnail"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                  </Box>
                 

                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" gutterBottom>
                      {download.title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="#797979">
                    {moment(download.createdAt).format("LL")}
                  </Typography>
                  <IconButton onClick={() => deleteDownload(download.id)}>
                    <DeleteOutline color="error"/>
                  </IconButton>
                </Box>
              </CardContent>
              <Divider />
            </Card>
          ))}
        </>
      )}
    </Container>
  );
};
