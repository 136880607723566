import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SignUp } from "./components/authorization/SignUp";
import { Login } from "./components/authorization/Login";
import { NotFound } from "./components/authorization/NotFound";
import { VideoPlayer } from "./components/video-stream/VideoPlayer";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import { Home } from "./components/Home";
import { UploadVideo } from "./components/admin/UploadVideo";
import { Settings } from "./components/settings/Settings";
import { SearchProvider } from "./components/context/SearchContext";
import { Users } from "./components/admin/users/Users";
import { CreateUser } from "./components/admin/users/CreateUser";
import { EditUser } from "./components/admin/users/EditUser";
import { EditVideo } from "./components/admin/EditVideo";
import { ShowSingleUser } from "./components/admin/users/ShowSingleUser";
import { FavoriteVideos } from "./components/FavoriteVideos";
import RolePermissionList from "./components/admin/role-and-permissions/RoleAndPermissions";
import { Videos } from "./components/admin/videos/videos";
import { Downloads } from "./components/Downloads";
import DonationForm from "./components/DonationForm";
import CheckoutForm from "./components/CheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import CreateTagForm from "./components/admin/tags/createTags";
import { TagsList } from "./components/admin/tags/listTags";
import PrivacyPolicy from "./components/privacyPolicy";
import { Shorts } from "./components/Shorts";


const stripePromise = loadStripe('your-publishable-key-here');

export const App = () => {
  const authToken = localStorage.getItem("authToken");
  const isLoggedIn = authToken !== null;
//comment
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <SearchProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/home" element={<Home />} />
            <Route path="/donation" element={<DonationForm />} />
            <Route path="/video/:id" element={<VideoPlayer />} />
            {isLoggedIn ? (
              <Route path="/settings" element={<Settings />} />
            ) : (
              <Route path="/settings" element={<Navigate to="/login" />} />
            )}
            <Route path="/favorites" element={<FavoriteVideos />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/shorts" element={<Shorts />} />
            <Route path="/admin/upload-video" element={<UploadVideo />} />
            <Route path="/admin/edit/:id" element={<EditVideo />} />
            <Route path="/admin/users" element={<Users />} />
            <Route path="/admin/users/create" element={<CreateUser />} />
            <Route path="/admin/users/edit/:id" element={<EditUser />} />
            <Route path="/admin/users/:id" element={<ShowSingleUser />} />
            <Route path="/admin/videos" element={<Videos />} />
            <Route path="/admin/tags" element={<TagsList />} />
            <Route path="/admin/tags/create" element={<CreateTagForm />} />
            <Route path="/admin/role-and-permissions" element={<RolePermissionList />} />
            <Route
              path="/checkout"
              element={
                <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </SearchProvider>
    </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
