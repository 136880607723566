import React, { useState } from "react";
import {
  Box,
  Container,
  useTheme,
  Typography,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { Favorite, FavoriteBorder, Share } from "@mui/icons-material"; // Import Share icon
import moment from "moment";
import { useQuery } from "react-query";
import { fetchVideos, makeVideoFavorite } from "./APIS/videos";
import { Element } from "react-scroll"; // Import from react-scroll
import { toast } from "react-toastify";


export const Shorts = () => {
  const theme = useTheme();
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const { data: videos, isLoading, refetch } = useQuery(
    ["videos", currentPage, itemsPerPage],
    () => fetchVideos(currentPage, itemsPerPage),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: currentPage !== undefined,
    }
  );

  const handleFavoriteToggle = async (video) => {
    toast.promise(
      makeVideoFavorite(video._id, !video.isFavorite),
      {
        pending: 'Adding to favorites...',
        success: 'Video added to favorites!',
        error: 'Failed to add to favorites'
      }
    );

    refetch();
  };

  const handleShare = (video) => {
    const shareData = {
      title: video.name,
      text: `Check out this video: ${video.name}`,
      url: video.videos3link, // URL to share
    };

    if (navigator.share) {
      navigator.share(shareData)
        .then(() => {
          console.log('Video shared successfully');
        })
        .catch((error) => {
          console.error('Error sharing video:', error);
        });
    } else {
      navigator.clipboard.writeText(video.videos3link).then(() => {
        toast.success('Video link copied to clipboard!');
      }).catch((error) => {
        toast.error('Failed to copy link');
      });
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: 0,
        height: "100vh",
        position: "relative",
      }}
    >
      {videos?.videos?.length === 0 || isLoading ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"100%"}
        >
          <Typography variant="h3" fontWeight={700}>
            No Videos to Show
          </Typography>
        </Box>
      ) : (
        videos?.videos?.map((video, index) => (
          <Element name={`video-${index}`} key={video.id}>
            <Card
              sx={{
                position: "relative",
                borderRadius: 5,
                backgroundColor: "#000",
                overflow: "hidden",
                height: "100vh",
                cursor: "pointer",
                mb: 2,
              }}
            >
              <video
                src={video.videos3link}
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />

              <CardContent
                sx={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  color: "white",
                  background: "rgba(0, 0, 0, 0.5)",
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  zIndex: 2,
                }}
              >
                <Box>
                  <Typography variant="h6">{video.name}</Typography>
                  <Typography variant="caption" color="grey.400">
                    {moment(video.createdat).format("LL")}
                  </Typography>
                </Box>
              </CardContent>

          

              {/* Swipe Up and Down Buttons */}
              <Box
                sx={{
                  position: "absolute",
                  right: 16,
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  zIndex: 2,
                }}
              >
                 <IconButton color={video.isFavorite ? "primary" : "default"} onClick={()=> handleFavoriteToggle(video)}>
                  {video.isFavorite ? <Favorite style={{color: "error"}} /> : <FavoriteBorder style={{color:"white"}} />}
                </IconButton>
                <IconButton color="default" onClick={() => handleShare(video)}>
                  <Share style={{color:"white"}} />
                </IconButton>
              </Box>
            </Card>
          </Element>
        ))
      )}
    </Container>
  );
};