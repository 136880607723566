import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Container,
  Typography,
  CircularProgress,
  Chip,
  IconButton,
  Tooltip,
  alpha,
} from "@mui/material";
import { motion } from "framer-motion";
import { Edit, Delete, Visibility } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: `linear-gradient(45deg, ${theme.palette.success.dark} 30%, ${theme.palette.success.main} 90%)`,
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.common.white, 0.05)
        : alpha(theme.palette.success.light, 0.05),
  },
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.common.white, 0.1)
        : alpha(theme.palette.success.light, 0.1),
    transition: "background-color 0.3s ease",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MotionTableRow = motion(StyledTableRow);

const ActionButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "50%",
  padding: theme.spacing(1),
  margin: theme.spacing(0, 0.5),
  backgroundColor: alpha(theme.palette.success.main, 0.1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.success.main, 0.2),
  },
}));

export const ListTable = ({
  data,
  error,
  isLoading,
  page,
  rowsPerPage,
  totalItems,
  onPageChange,
  onRowsPerPageChange,
  onEdit,
  onDelete,
  onView,
  permissions,
}) => {
  const theme = useTheme();

  const uniqueHeadings = [
    ...new Set(data?.flatMap((rowData) => rowData.map((item) => item.title))),
  ];

  if (!uniqueHeadings.includes("Actions")) {
    uniqueHeadings.push("Actions");
  }

  const getChipColor = (value) => {
    if (value === "Active" || value === "Completed") return "success";
    if (value === "Pending") return "warning";
    if (value === "Inactive" || value === "Cancelled") return "error";
    return "default";
  };

  const renderCellContent = (value) => {
    if (typeof value === "boolean") {
      return value ? (
        <Chip label="Yes" color="success" size="small" />
      ) : (
        <Chip label="No" color="error" size="small" />
      );
    }
    if (
      ["Active", "Inactive", "Pending", "Completed", "Cancelled"].includes(
        value
      )
    ) {
      return <Chip label={value} color={getChipColor(value)} size="small" />;
    }
    return value;
  };

  return (
    <Container maxWidth="xl">
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          borderRadius: 2,
          overflowX: "auto",
          background:
            theme.palette.mode === "dark"
              ? alpha(theme.palette.grey[900], 0.8)
              : theme.palette.common.white,
        }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {uniqueHeadings.map((heading) => (
                <StyledTableCell key={heading} align="left">
                  {heading}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <StyledTableCell
                  colSpan={uniqueHeadings.length}
                  align="center"
                  sx={{ py: 5 }}
                >
                  <CircularProgress
                    sx={{ color: theme.palette.success.main }}
                  />
                </StyledTableCell>
              </TableRow>
            ) : !data || data.length === 0 ? (
              <TableRow>
                <StyledTableCell
                  colSpan={uniqueHeadings.length}
                  align="center"
                  sx={{ py: 5 }}
                >
                  <Typography variant="h6" color="text.secondary">
                    {error || "No data available"}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ) : (
              data.map((rowData, index) => (
                <MotionTableRow
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                >
                  {uniqueHeadings.map((heading) => {
                    if (heading === "Actions") {
                      return (
                        <StyledTableCell key={heading} align="left">
                          {permissions.canView && (
                            <Tooltip title="View">
                              <ActionButton
                                onClick={() => onView(rowData)}
                                size="small"
                              >
                                <Visibility />
                              </ActionButton>
                            </Tooltip>
                          )}
                          {permissions.canEdit && (
                            <Tooltip title="Edit">
                              <ActionButton
                                onClick={() => onEdit(rowData)}
                                size="small"
                              >
                                <Edit />
                              </ActionButton>
                            </Tooltip>
                          )}
                          {permissions.canDelete && (
                            <Tooltip title="Delete">
                              <ActionButton
                                onClick={() => onDelete(rowData)}
                                size="small"
                              >
                                <Delete />
                              </ActionButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      );
                    }
                    const cellValue = rowData.find(
                      (item) => item.title === heading
                    )?.value;
                    return (
                      <StyledTableCell key={heading} align="left">
                        {renderCellContent(cellValue)}
                      </StyledTableCell>
                    );
                  })}
                </MotionTableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          sx={{
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
              {
                m: 1,
              },
            ".MuiTablePagination-select": {
              color: theme.palette.text.primary,
            },
          }}
        />
      </TableContainer>
    </Container>
  );
};
